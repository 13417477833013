import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// element-plus
import '@assets/styles/element-plus/index.scss'
// style
import '@assets/styles/index.scss'
// interceptor - 路由拦截器
import '@/router/interceptor'
// empty-box - 空组件
import EmptyBox from '@/components/EmptyBox/index'
import ElcDatePicker from '@/components/ElcDatePicker/index'
// 自定义指令
import directive from '@/directive'

const app = createApp(App)
directive.install(app)
app.use(EmptyBox)
app.use(ElcDatePicker)
app.use(store).use(router).mount('#app')

class PromiseReject extends Error {
  data: undefined
  constructor(data) {
    super()
    try {
      this.data = JSON.parse(data)
    } catch (e) {
      this.data = data
    }
  }
}
declare global{
  interface Window { PromiseReject: PromiseReject|any }
}
window.PromiseReject = PromiseReject
