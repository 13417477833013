import { RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  { path: '/', redirect: '/login' },
  { path: '/login', name: 'Login', component: () => import('@/views/login/index.vue') },
  // { path: '/test', name: 'TestParent', component: () => import('@/views/test/TestParent.vue') },
  // { path: '/theme', name: 'Theme', component: () => import('@/views/theme/index.vue') },
  { path: '/draw-edit/:id', name: 'DrawEdit', component: () => import('@/views/TestDraw.vue') },
  { path: '/applet-draw', name: 'AppletShowDraw', component: () => import('@/views/siteOverview/AppletShowDraw.vue') },
]

export default { routes }
