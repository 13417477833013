import interceptorConfig from './config'
import { getToken } from '@/utils/auth'
import { RouteLocationNormalized } from 'vue-router'
import store from '@/store'

interface AuthResult { state: boolean, toPath?: string, authorization?: boolean }

/**
 * 认证
 * @param to
 */
export const authentication = (to: RouteLocationNormalized): AuthResult => {
  let config = interceptorConfig.default
  const authResult: AuthResult = { state: true }
  for(const key in interceptorConfig) {
    if(new RegExp(key.replace(/\*/g, '.*') + '$').test(to.path)) {
      config = interceptorConfig[key]; break
    }
  }
  const token = getToken()
  // 如果页面需要token且没有
  if(config.token && !token) {
    authResult.state = false
    authResult.toPath = config.notTokenTo
  } else if(token && config.tokenTo) {
    // 如果页面不需要token且有token和需要跳转的地址
    authResult.state = false
    authResult.toPath = config.tokenTo
    // 如果需要token且有,验证权限
  } else if(config.token) {
    authResult.authorization = true
  }
  return authResult
}

/**
 * 授权
 * @param to
 */
export const authorization = async(to: RouteLocationNormalized) => {
  if(getToken()) {
    if(!store.getters['app/userInfo'].id) {
      await store.dispatch('app/updateUserInfo').then(res => {
        // 授权通过
        if(!res.state) {
          throw new Error(JSON.stringify(res))
        }
      }).catch((e) => {
        const code = 403
        const _code = e.toString().replace(/.*[code ](.*)/, '$1')
        throw new Error('{ "state": ' + (_code || code) + ' }')
      })
    }
    if(to.meta.role) {
      if(!to.meta.role.split(';').includes(store.getters['app/userInfo'].sysRole)) {
        throw new Error(JSON.stringify({ state: 403 }))
      }
    }
  }
}
