import axios from 'axios'
import qs from 'qs'
import { getToken, removeToken } from '@/utils/auth'
import { ElMessage } from 'element-plus'
import router from '@/router'

interface Pending {
  time: number,
  count: number
}

interface Payload {
  code: number,
  message?: string,
  payload: {
    data: any,
    [p: string]: any
  }
}

declare module 'axios' {
  export interface AxiosResponse {
    result(): Promise<Payload>
  }
}

const pending: Record<string, Pending> = {} // 网络请求记录
const CancelToken = axios.CancelToken

const request = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  headers: {
    // 'Content-Type': 'application/json'
  },
  timeout: 120000,
  // transformRequest: [function(data, headers) {
  //   return qs.stringify(data, { arrayFormat: 'repeat' })
  // }],
})
// 初始化token
const token = getToken()
token && (request.defaults.headers.common['YK'] = token)

// 请求拦截器
request.interceptors.request.use(
  config => {
    return config
    // 验证是否需要拦截
    // 判断请求地址,不拦截非'/'开头的请求,如其他服务器地址'http://www.xxx.com/'
    // if(config.url && config.url.substr(0, 1) !== '/') return config
    // if(config.headers && config.headers['notCancel']) {
    //   delete config.headers['notCancel']
    //   return config
    // }
    // // 生成记录key
    // const key = `${config.url}&${config.method}&${JSON.stringify(config.data)}`
    // // 设置重复请求
    // config.cancelToken = new CancelToken(c => {
    //   // 如果已经被记录,验证是否可再次请求
    //   if(pending[key]) {
    //     // 如果未超时,拦截请求,并更新拦截时间,否则删除记录
    //     if(Date.now() - pending[key].time <= 500) {
    //       // 500ms以内的已发起请求，取消重复请求
    //       if(++pending[key].count >= 3) {
    //         c('Cancelled your repeated request')
    //         ElMessage.warning('您的操作过快，请不要频繁操作')
    //       }
    //       // 更新时间戳
    //       pending[key].time = Date.now()
    //     } else {
    //       // 超过1s，删除对应的请求记录，重新发起请求
    //       delete pending[key]
    //       // pending[key].count = 0
    //     }
    //   }
    //   if(!pending[key]) {
    //     // 记录当前的请求
    //     pending[key] = { time: Date.now(), count: 0 } // time 最近触发时间，count 拦截次数
    //   }
    // })
    // return config
  },
  error => {
    return Promise.reject(error)
  },
)

// 响应拦截器
request.interceptors.response.use(
  response => {
    // 如果token失效或无token
    if(response.data.code === 666) {
      // 如果无token,跳转到登陆,如果有则清除并给出提示
      if(getToken()) {
        removeToken()
        ElMessage.warning('无登录信息或登录信息已过期')
      }
      router.replace({ name: 'Login' })
    }
    response.result = function() {
      return new Promise<Payload>((resolve, reject) => {
        if(response.data.code) {
          switch (response.data.code) {
            case 200:
              resolve(response.data)
              break
            case 666:
              reject(response.data)
              break
            default:
              reject(response.data)
              ElMessage.warning(response.data.message)
              break
          }
        }
      })
    }
    return response
  },
  error => {
    if(error.toString().indexOf('timeout') > -1) {
      ElMessage.warning('请求超时，请稍后重试')
    } else {
      const code = error.toString().replace(/^.*[code] (.*)$/, '$1')
      switch (Number(code)) {
        case 400:
          ElMessage.warning('请求参数有误，400')
          break
        case 404:
          ElMessage.warning('您访问的资源不存在，404')
          break
        case 405:
          ElMessage.warning('请求方法不能被用于请求相应的资源，405')
          break
        case 415:
          ElMessage.warning('请求不支持该媒体类型，415')
          break
        case 500:
          ElMessage.error('请求服务器错误')
          break
        case 403:
          ElMessage.warning('服务器拒绝了您的请求，403')
          break
        default:
          if(code > 500) {
            ElMessage.error('请求服务器出现异常，' + code)
          }
      }
    }
    return Promise.reject(error)
  },
)

export default request
