/* eslint-disable */
import { matchUnit } from '@/utils/ObjectUtil/matchUnit'

declare type forEachCallback<T> = (value: T, key: string, index: number) => void
/**
 * @param deep : boolean 仅合并现有属性
 * @param object : boolean 是否对object类型深合并
 * @param array : boolean 是否对array类型深合并
 */
declare type assignDeepOption = { deep?: boolean, object?: boolean, array?: boolean }

export class ObjectUtil {

  /**
   * 延迟
   * @param delay
   */
  static sleep(delay: number) {
    return new Promise((resolve) => {
      setTimeout(() => { resolve(undefined) }, delay)
    })
  }

  /**
   * 根据地址获取源数据中的值
   * @param source
   * @param address
   */
  static getAttr(source: undefined | any, address: string, char = '/'): any {
    const tier = address.split(char)
    try {
      tier.forEach(key => {
        source = source[key]
      })
      return source
    } catch (e) {
      return undefined
    }
  }

  /**
   * 遍历对象或数组
   * @param source
   * @param callback
   */
  static forEach<T>(source: Record<string, T>, callback: forEachCallback<T>): void {
    let index = 0
    for(const key in source) {
      callback(source[key], key, index)
      index++
    }
  }

  /**
   * 判断参数是否为null|undefined|''
   * @param value
   */
  static isNotEmpty(value: unknown): boolean {
    return value === 0 || !!value
  }

  /**
   * 判断参数是否为null|undefined|''
   * @param value
   */
  static isEmpty(value: unknown): boolean {
    return !this.isNotEmpty(value)
  }

  /**
   * 如果value不为空返回value否则返回arg
   * @param value
   * @param arg
   */
  static isEmptyToVal<T, P>(value: T, arg: P): T | P {
    const result = this.isNotEmpty(value)
    return result ? value : arg
  }

  /**
   * 根据source更新target中的属性值
   * @param target
   * @param source
   */
  static updateVal(target: Record<string, unknown>, source: Record<string, any>): void {
    for(const key in target) {
      target[key] = source[key]
    }
  }

  /**
   * 把数据源根据配置生成一个新的对象
   * @param source
   * @param props
   */
  static corresponding <T = Record<string, string>>(source: any, props: T): T {
    for(const key in props) {
      props[key] = source[props[key]]
    }
    return props as unknown as T
  }

  /**
   * 拷贝
   * @param arg
   */
  static copy<T>(arg: T): T {
    return JSON.parse(JSON.stringify(arg))
  }

  /**
   * 深度拷贝
   * @param from
   */
  static deepCopy<T>(from: T): T {
    return this._deepCopy_(from)
  }

  /**
   * deepCopy的递归函数
   * @param from
   * @param to
   * @private
   */
  private static _deepCopy_(from: any, to?: any): any {
    if(Array.isArray(from)) {
      to = []
      from.forEach((item: unknown, index: number) => {
        to[index] = typeof from[index] === 'object' ? this._deepCopy_(from[index], to[index]) : from[index]
      })
    } else {
      if(!this.isObject(from)) {
        return from
      }
      to = {}
      for(const key in from) {
        to[key] = typeof from[key] === 'object' ? this._deepCopy_(from[key], to[key]) : from[key]
      }
    }
    return to
  }

  /**
   * 合并两个对象
   * @param target
   * @param source
   * @param option
   */
  static assignDeep<T, U>(target: T, source: U, option?: assignDeepOption): T & U {
    const _option_ = Object.assign({ deep: true, object: true, array: true }, option || {})
    this._assignDeep_(target, source, _option_)
    return <T & U>target
  }

  /**
   * assignDeep 的递归函数
   * @param target
   * @param source
   * @param option
   * @private
   */
  private static _assignDeep_(target: any, source: any, option: assignDeepOption): void {
    for(const key in source) {
      const val = source[key]
      if((this.isObject(val) && this.isObject(target[key]) && option.object) || (Array.isArray(val) && Array.isArray(target[key]) && option.array)) {
        this._assignDeep_(target[key], val, option)
      } else {
        if(option.deep || target[key] !== undefined) {
          target[key] = val
        }
      }
    }
  }

  /**
   * 通过提高单位缩短数据长度
   */
  static matchUnit = matchUnit

  static isBoolean(arg: unknown): arg is boolean {
    return Object.prototype.toString.call(arg) === '[object Boolean]'
  }

  static isString(arg: unknown): arg is string {
    return Object.prototype.toString.call(arg) === '[object String]'
  }

  static isNumber(arg: unknown): arg is number {
    return Object.prototype.toString.call(arg) === '[object Number]'
  }

  static isObject(arg: unknown): arg is Global.Object {
    return Object.prototype.toString.call(arg) === '[object Object]'
  }

  static isDate(arg: unknown): arg is Date {
    return Object.prototype.toString.call(arg) === '[object Date]'
  }

  static isUndefined(arg: unknown): arg is undefined {
    return typeof arg === 'undefined'
  }

  static isPrototype<T>(arg: unknown, type: string): arg is T {
    return Object.prototype.toString.call(arg) === '[object ' + type + ']'
  }

  static toPrototype<T>(arg: unknown): arg is T {
    return true
  }
}

export class NumberUtil {
  /**
   * 保留最大小数位数
   * @param value
   * @param fractionDigits
   */
  static toFixed(value: string | number, fractionDigits: number): number {
    return Number(Number(value).toFixed(fractionDigits))
  }
}
