import router from '@/router'
import nProgress from 'nprogress'
import 'nprogress/nprogress.css'
import settings from '@/settings'
import { authentication, authorization } from '@/router/interceptor/permission'
import { setToken } from '@utils/auth'

// 添加路由前置守卫
router.beforeEach(async(to, from, next) => {
  nProgress.start()
  // window.loadingBox.show()
  if(to.query.token) {
    setToken(to.query.token as string)
  }
  // 身份验证
  const authResult = authentication(to)
  if(authResult.state) {
    // 授权验证
    const { title } = to.meta
    document.title = `${title ? title + ' - ' : ''}${settings.TITLE}`
    if(authResult.authorization) {
      await authorization(to).then(() => {
        next()
      }).catch((e) => {
        const result = JSON.parse(e.message)
        if(result.state === false) {
          next(false)
        } else {
          next('/error/' + result.state)
        }
      })
    } else {
      next()
    }
  } else {
    // window.loadingBox.hide()
    nProgress.done()
    next(authResult.toPath!)
  }
})

// 添加路由后置守卫
router.afterEach((to, from) => {
  // window.loadingBox.hide()
  nProgress.done()
})
