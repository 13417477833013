import { RouteRecordRaw } from 'vue-router'
import Layout from '@/layout/index.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/gis', name: 'GisParent', component: Layout, redirect: '/gis/index',
    children: [
      {
        path: 'index', name: 'Gis',
        meta: { title: '地图总览', icon: 'icon-shouye1' },
        component: () => import('@/views/Gis.vue'),
      }
    ]
  },
  {
    path: '/overview', name: 'Overview', component: Layout,
    children: [
      {
        path: 'site/:sid', name: 'SiteOverview', redirect: '/overview/site/0/flow-chart',
        meta: { title: '站点概览', icon: 'icon-zhandiangailan' },
        component: () => import('@/views/siteOverview/index.vue'),
        children: [
          {
            path: 'flow-chart', name: 'FlowChart',
            meta: { title: '工艺流程', menu: false },
            component: () => import('@/views/siteOverview/FlowChart.vue'),
          },
          {
            path: 'realtime', name: 'Realtime',
            meta: { title: '实时数据', menu: false },
            component: () => import('@/views/siteOverview/RealTime.vue'),
          },
          {
            path: 'alarm-record', name: 'AlarmRecord',
            meta: { title: '报警记录', menu: false },
            component: () => import('@/views/siteOverview/AlarmRecord.vue'),
          },
          {
            path: 'data-record', name: 'DataRecord',
            meta: { title: '数据记录', menu: false },
            component: () => import('@/views/siteOverview/DataRecord.vue'),
          },
          {
            path: 'revstop', name: 'SiteOverviewRevStopReport',
            meta: { title: '启停记录', menu: false },
            component: () => import('@/views/siteOverview/RevStopReport.vue'),
          },
          {
            path: 'inspection', name: 'SiteOverviewInspectionRecord',
            meta: { title: '巡检记录', menu: false },
            component: () => import('@/views/siteOverview/InspectionRecord.vue'),
          },
          {
            path: 'maintain', name: 'SiteOverviewMaintainRecord',
            meta: { title: '维修记录', menu: false },
            component: () => import('@/views/siteOverview/MaintainRecord.vue'),
          }
        ]
      }
    ]
  },
  {
    path: '/alarm/manage', name: 'Alarm', component: Layout, redirect: '/alarm/manage/history',
    meta: { title: '报警管理', icon: 'icon-baojingguanli' },
    children: [
      {
        path: 'history', name: 'HistoryAlarm',
        meta: { title: '历史报警', icon: 'icon-baojingguanli-1' },
        component: () => import('@/views/alarmMange/HistoryAlarm.vue'),
      },
      {
        path: 'rule/:sid', name: 'AlarmRule',
        meta: { title: '报警规则', icon: 'icon-baojingguize', role: '1;2' },
        component: () => import('@/views/alarmMange/AlarmRule.vue'),
      },
      // {
      //   path: 'setting', name: 'NoticeSetting',
      //   meta: { title: '推送设置', icon: 'icon-xitongtuisong' },
      //   component: () => import('@/views/alarmMange/NoticeSetting.vue'),
      // }
    ]
  },
  {
    path: '/inspection', name: 'Inspection', component: Layout, redirect: '/inspection/manage',
    meta: { title: '点检管理', icon: 'icon-xunjian1' },
    children: [
      {
        path: 'manage', name: 'InspectionRecord',
        meta: { title: '巡检记录', icon: 'icon-xunjianguanli' },
        component: () => import('@/views/inspectionManage/InspectionRecord.vue'),
      },
      {
        path: 'manage/details/:id', name: 'InspectionDetails',
        meta: { title: '巡检详情', icon: '', menu: false, activeMenu: 'InspectionRecord' },
        component: () => import('@/views/inspectionManage/InspectionDetails.vue'),
      },
      {
        path: 'maintain', name: 'MaintainRecord',
        meta: { title: '维修记录', icon: 'icon-weixiuguanli' },
        component: () => import('@/views/inspectionManage/MaintainRecord.vue'),
      },
      {
        path: 'maintain/details/:id', name: 'MaintainDetails',
        meta: { title: '维修详情', icon: '', menu: false, activeMenu: 'MaintainRecord' },
        component: () => import('@/views/inspectionManage/MaintainDetails.vue'),
      },
    ]
  },
  {
    path: '/report/manage', name: 'ReportManage', component: Layout, redirect: '/report/manage/history',
    meta: { title: '报表管理', icon: 'icon-baobiao' },
    children: [
      // {
      //   path: 'history/:sid', name: 'HistoryData',
      //   meta: { title: '历史数据', icon: 'icon-lishibaobiao' },
      //   component: () => import('@/views/reportManage/HistoryData.vue'),
      // },
      {
        path: 'revstop/:sid', name: 'RevStopReport',
        meta: { title: '启停记录', icon: 'icon-qitingjilu' },
        component: () => import('@/views/reportManage/RevStopReport.vue'),
      },
      {
        path: 'modify/:sid', name: 'ModifyReport',
        meta: { title: '修改记录', icon: 'icon-xiugaijilu' },
        component: () => import('@/views/reportManage/ModifyReport.vue'),
      },
      {
        path: 'custom', name: 'CustomReport',
        meta: { title: '自定义报表', icon: 'icon-zidingyibaobiao' },
        component: () => import('@/views/reportManage/CustomReport.vue'),
      },
    ]
  },
  // {
  //   path: '/video/manage', name: 'VideoManage', component: Layout, redirect: '/video/manage/monitoring',
  //   meta: { title: '视频管理', icon: 'icon-jiankong' },
  //   children: [
  //     {
  //       path: 'monitoring/:sid', name: 'VideoMonitoring',
  //       meta: { title: '视频监控', icon: 'icon-shipinjiankong' },
  //       component: () => import('@/views/videoManage/VideoMonitoring.vue'),
  //     },
  //     {
  //       path: 'config', name: 'VideoConfig',
  //       meta: { title: '视频配置', icon: 'icon-shipinpeizhi' },
  //       component: () => import('@/views/videoManage/VideoConfig.vue'),
  //     },
  //   ]
  // },
  {
    path: '/plc/manage', name: 'PLC', component: Layout, redirect: '/plc/manage/index',
    meta: { role: '1;2' },
    children: [
      {
        path: 'index', name: 'PLCManage',
        meta: { title: 'PLC管理', icon: 'icon-zutai', role: '1;2' },
        component: () => import('@/views/PLCManage/index.vue'),
      }
    ]
  },
  {
    path: '/draw-manage', name: 'Draw', component: Layout, redirect: '/draw-manage/index',
    meta: { role: '1;2' },
    children: [
      {
        path: 'index', name: 'DrawManage',
        meta: { title: '工艺图管理', icon: 'icon-danchuang', role: '1;2' },
        component: () => import('@/views/drawManage/index.vue'),
      }
    ]
  },
  {
    path: '/city', name: 'City', component: Layout, redirect: '/city/manage',
    meta: { role: '1;2' },
    children: [
      {
        path: 'manage', name: 'CityManage',
        meta: { title: '城镇管理', icon: 'icon-chengzhen', role: '1;2' },
        component: () => import('@/views/CityManage.vue'),
      },
    ]
  },
  {
    path: '/site', name: 'Site', component: Layout, redirect: '/site/manage',
    meta: { role: '1;2' },
    children: [
      {
        path: 'manage', name: 'SiteManage',
        meta: { title: '站点管理', icon: 'icon-zhandian', role: '1;2' },
        component: () => import('@/views/SiteManage.vue'),
      },
    ]
  },
  {
    path: '/system/manage', name: 'System', component: Layout, redirect: '/system/manage/user',
    meta: { title: '系统管理', icon: 'icon-xitongshezhi', role: '1;2' },
    children: [
      {
        path: 'user', name: 'UserManage',
        meta: { title: '用户管理', icon: 'icon-yonghuguanli', role: '1;2' },
        component: () => import('@/views/systemManage/UserManage.vue'),
      },
      {
        path: 'log', name: 'LogManage',
        meta: { title: '日志管理', icon: 'icon-icon', role: '1;2' },
        component: () => import('@/views/systemManage/LogManage.vue'),
      },
    ]
  },
  {
    path: '/user', name: 'User', component: Layout, redirect: '/user/info',
    meta: { title: '用户', menu: false },
    children: [{
      path: 'info', name: 'UserInfo',
      meta: { title: '个人信息', icon: 'icon-yonghuguanli', menu: false, activeMenu: 'User' },
      component: () => import('@/views/UserInfo.vue'),
    }]
  }
]

export default { routes, menu: true }
