import { RouteRecordRaw } from 'vue-router'
import store from '@/store'
import { menuRoutes } from '@/router'
import { ObjectUtil } from '@/utils/ObjectUtil'

/**
 * 获取菜单列表
 */
export function getMenuList(): RouteRecordRaw[] {
  const menuList = formatMenu(ObjectUtil.copy(menuRoutes))
  return menuList.filter((menu) => {
    // 时间紧急，复制粘贴，勿骂
    const sysRole = store.getters['app/userInfo'].sysRole
    if(sysRole && !(menu.meta?.role && !menu.meta.role.split(';').includes(sysRole))) {
      if(menu.children.length > 1) {
        menu.children = menu.children.filter((menu2) => {
          if(!(menu2.meta?.role && !menu2.meta.role.split(';').includes(sysRole))) {
            return menu2
          }
        })
      }
      return menu
    }
  })
}

/**
 * 格式化菜单
 * @param childrenMenu
 * @param parentMenu
 */
function formatMenu(childrenMenu: Array<RouteRecordRaw>, parentMenu?: RouteRecordRaw) {
  for(let i = 0, len = childrenMenu.length; i < len; i++) {
    const menu = childrenMenu[i]
    if(parentMenu) {
      menu.path = parentMenu.path + '/' + menu.path
    }
    if(menu.children && menu.children?.length > 0) {
      formatMenu(menu.children, menu)
    }
  }
  return childrenMenu
}
