import { createRouter, createWebHashHistory, RouteRecordRaw } from 'vue-router'
import { ObjectUtil } from '@utils/ObjectUtil'
// /* webpackChunkName: "ChunkName" */
declare module 'vue-router' {
  interface RouteMeta {
    title?: string, icon?: string, activeMenu?: string, role?: string, menu?: boolean
  }
}

const modulesFiles = require.context('./modules', true, /\.ts$/)
// 排序规则
const menuOrder: string[] = []
// 排序路由存放
const orderRoutes: RouteRecordRaw[][] = []
// 非排序路由存放
const otherRoutes: RouteRecordRaw[] = []
// 菜单路由
export const menuRoutes: RouteRecordRaw[] = []
const routes: Array<RouteRecordRaw> = []

/**
 * 过滤不加入菜单的路由
 * @param rs
 */
const filterRoutes = (rs: RouteRecordRaw[]): RouteRecordRaw[] => {
  return rs.filter((r) => {
    if(r.meta?.menu !== false) {
      if(r.children && r.children.length > 0) {
        r.children = filterRoutes(r.children)
      }
      return r
    }
  })
}

modulesFiles.keys().forEach((modulePath) => {
  const moduleName = modulePath.replace(/^\.\/(.*)\.\w+$/, '$1')
  const value = modulesFiles(modulePath)
  routes.push(...value.default.routes)
  if(value.default.menu) {
    const index = menuOrder.indexOf(moduleName)
    const menu = filterRoutes(ObjectUtil.copy(value.default.routes))
    if(index > -1) {
      orderRoutes[index] = menu
    } else {
      otherRoutes.push(...menu)
    }
  }
})

// 合并排序的路由
orderRoutes.forEach((routes) => {
  menuRoutes.push(...routes)
})
menuRoutes.push(...otherRoutes)

const router = createRouter({
  history: createWebHashHistory(),
  routes
})

export default router
