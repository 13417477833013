import { createModule } from '@/store'
import { User } from '@/typings/user'
import { getToken } from '@/utils/auth'
import { getUserInfoAPI } from '@/api/auth'

export default createModule({
  namespaced: true,
  state: {
    userInfo: {} as User
  },
  mutations: {
    setUserInfo(state, value) {
      state.userInfo = value
    },
  },
  actions: {
    updateUserInfo({ commit }) {
      return new Promise((resolve, reject) => {
        if(getToken()) {
          getUserInfoAPI().then(res => {
            if(res.data.code === 200) {
              const data = res.data.payload.data
              data.townName = res.data.payload.townName
              commit('setUserInfo', data)
              resolve({ state: true })
            } else {
              resolve({ state: false, res })
            }
          }).catch(reject)
        }
      })
    }
  },
  getters: {
    userInfo: state => state.userInfo,
  },
})
