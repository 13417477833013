import moment from 'moment'
moment.locale('zh-cn')

export enum DateFormat {
  time = 'HH:mm:ss',
  date = 'YYYY-MM-DD',
  datetime = 'YYYY-MM-DD HH:mm:ss'
}

/**
 * 获取近几天
 * @param day
 */
export function recentDate(day: number): string[] {
  const today = moment()
  const before = moment().add(-day, 'days')
  return [before.format(DateFormat.date), today.format(DateFormat.date)]
}

/**
 * 计算两时间间隔
 * @param start
 * @param end
 * @return xx天xx小时...
 */
export function duration(start: string, end: string): string {
  const duration = moment.duration(moment(end).diff(moment(start)))
  const dic = { days: '天', hours: '小时', minutes: '分钟', seconds: '秒' } as Global.Object
  let dateStr = ''
  for(const key in dic) {
    const time = (<any>duration)[key]()
    time && (dateStr += time + dic[key])
  }
  return dateStr
}

export const shortcuts = [
  { text: '近7天', value: recentDate(7) },
  { text: '近30天', value: recentDate(30) },
]

export default moment
